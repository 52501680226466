import gsap from 'gsap'
import * as PIXI from 'pixi.js'
import Board from './Board'
import Card from './Card'
import CommonBoard from './CommonBoard'
import { LevelOfDetailEnum } from './LevelOfDetailManager'
import Physics from './Physics'
import ScrollBar from './ScrollBar'

export default class Column extends PIXI.Container {

  public id: number
  public cardsCount: number
  public heightInPt: number
  public title: number
  public firstCardId: number
  public lastCardId: number

  private _cards: Card[] = []
  private _cardsContianer: PIXI.Container
  private _cardsContianerMask: PIXI.Container

  private static _padding: number = 10
  private static _marginBetweenCards = 5
  private _lastLoadArea: { top: number, bottom: number } | null = null

  private _isDraggingValue: boolean = false
  private get _isDragging() {
    return this._isDraggingValue
  }
  private set _isDragging(value: boolean) {
    this._isDraggingValue = value
    if (value) {
      this._disableCameraController()
      return
    }
    this._enableCameraController()
  }

  private _isScrollingValue: boolean = false
  private get _isScrolling() {
    return this._isScrollingValue
  }
  private set _isScrolling(value: boolean) {
    this._isScrollingValue = value
    if (value) {
      CommonBoard.enableAutoRender('column_id_' + this.id)
      return
    }
    CommonBoard.disableAutoRender('column_id_' + this.id)
  }

  private _scrollYInCoef: number = 0
  private _scrollingStartY: number = 0
  private _scrollingStartMouseY: number = 0
  private _scrollingGsap: gsap.core.Tween | null = null
  private _scrollingMouseMovementHistory: { time: number, posY: number }[]  = []

  private _scrollLastPage: number | null = null
  private _scrollYInPxValue = 0
  private get _scrollYInPx() {
    return this._scrollYInPxValue
  }
  private set _scrollYInPx(value: number) {
    // if (this._scrollYInPxValue === value) return
    let isBoundReached = false
    if (value >= 0) {
      value = 0
      isBoundReached = true
    }
    let minScrollYInPX = -this._getMaxColumnHeightInPx() + this.height
    if (value <= minScrollYInPX) {
      value = minScrollYInPX
      isBoundReached = true
    }
    this._scrollYInPxValue = value
    if (!isBoundReached) {
      this._scrollYInCoef = value === 0 && minScrollYInPX === 0 ? 0 : value / minScrollYInPX
    }

    // load data
    let scrollCurrentPage = Math.floor(this._scrollYInPx / this.height)
    if (scrollCurrentPage !== this._scrollLastPage) {
      CommonBoard.loadData()
    }
    this._scrollLastPage = scrollCurrentPage
  
    this._updateScrollbar()
    this._updateCardsYPoisition()
    CommonBoard.renderCustomly()

    // interrupt gsap on bound
    if (isBoundReached && this._scrollingGsap) this._scrollingGsap.kill()
  }
  private _scrollBar: ScrollBar
  private _bg: PIXI.Sprite
  private _lastHeight: number | null = null

  constructor(id: number, cardsCount: number, heightInPt: number, name: number, firstCardId: number, lastCardId: number) {

    super()

    this.id = id
    this.cardsCount = cardsCount
    this.heightInPt = heightInPt
    this.title = name
    this.firstCardId = firstCardId
    this.lastCardId = lastCardId

    this._bg = new PIXI.Sprite(PIXI.Texture.WHITE)
    this._bg.tint = 0xeeeeee
    this._bg.x = this.x
    this._bg.y = this.y
    this._bg.width = this._getColumnWidth()
    this._bg.height = window.innerHeight
    this.addChild(this._bg)

    this._scrollBar = new ScrollBar(this._bg.height, this.heightInPt)
    this._scrollBar.width = ScrollBar.width
    this._scrollBar.x = this._bg.width - ScrollBar.width
    this.addChild(this._scrollBar)

    this._cardsContianer = new PIXI.Container()
    this._cardsContianer.x = Column._padding
    this._cardsContianer.y = Column._padding
    this.addChild(this._cardsContianer)
    this._cardsContianerMask = new PIXI.Sprite(PIXI.Texture.WHITE)
    this._cardsContianerMask.width = this.width - (Column._padding * 2) - ScrollBar.width
    this._cardsContianerMask.height = this._getMaskHeight()
    this._cardsContianer.addChild(this._cardsContianerMask)
    this._cardsContianer.mask = this._cardsContianerMask

    this.interactive = true
    this.on('mousedown', this._onDown)
    this.on('mousemove', this._onMove)
    this.on('mouseup', this._onUp)
    this.on('mouseupoutside', this._onUp)
    this.on('touchstart', this._onDown)
    this.on('touchmove', this._onMove)
    this.on('touchend', this._onUp)
    this.on('touchendoutside', this._onUp)
  }

  public async setCameraPosition(lod: LevelOfDetailEnum, cameraVisibleBounds: PIXI.Rectangle, cameraLoadBounds: PIXI.Rectangle, loadedCards: any[]) {
    let maxHeight = this._getMaxColumnHeightInPx()
    let height = cameraVisibleBounds.height < maxHeight ? cameraVisibleBounds.height : maxHeight
    if (height != this._lastHeight) {
      this._bg.height = height
      this._cardsContianerMask.height = height - (Column._padding * 2)
      //
      let minScrollYInPX = -this._getMaxColumnHeightInPx() + this.height
      this._scrollYInPx = minScrollYInPX * this._scrollYInCoef
      this._lastHeight = height
    }
    // add cards
    loadedCards.forEach(loadedCard => {
      let card = this._cards.find(c => c.id === loadedCard.id)
      if (card) {
        if (lod !== LevelOfDetailEnum.ColumnCardTexts) return
        card.updateResolution()
        if (card.name === loadedCard.name) return
        card.setTitle(loadedCard.name)
        return
      }
      let cardBounds = new PIXI.Rectangle(
        this.parent.x + this._cardsContianer.x,
        // this.parent.y + this._cardsContianer.y + (loadedCard.before_height * Board.pixelsPerPoint) + (Column._marginBetweenCards * loadedCard.before_cards_count),
        this._getCardYInPx(loadedCard.before_height, loadedCard.before_cards_count),
        Card.width,
        loadedCard.height * Board.pixelsPerPoint
      )
      if (!Physics.hasCollision2d(cardBounds, cameraLoadBounds)) return
      this._addCard(loadedCard)
    })
    this._updateCardsYPoisition()
    this._updateCardsResolution()
    // set lod and remove outside cards
    for (let i = this._cards.length - 1; i >= 0; i--) {
      let cardBounds = new PIXI.Rectangle(
        this.parent.x + this.x + this._cards[i].x,
        this.parent.y + this.y + this._cards[i].y,
        this._cards[i].width,
        this._cards[i].heightInPts * Board.pixelsPerPoint
      )
      if (!Physics.hasCollision2d(cardBounds, cameraLoadBounds)) {
        this._cardsContianer.removeChild(this._cards[i]).destroy(true)
        this._cards.splice(i, 1)
        continue
      }
      this._cards[i].rectangles.visible = lod === LevelOfDetailEnum.ColumnCardRectangles
      this._cards[i].text.visible = lod === LevelOfDetailEnum.ColumnCardTexts
    }
  }

  public deleteCards() {
    for (let i = this._cards.length - 1; i >= 0; i--) {
      this._cardsContianer.removeChild(this._cards[i]).destroy(true)
      this._cards.splice(i, 1)
    }
  }

  public getLoadArea() {
    let localYPts = this._getLocalYPts()
    let scrollPosYInPts = Math.abs(this._scrollYInPx) / localYPts
    let pagePts = this.height / localYPts
    let loadArea = {
      top: scrollPosYInPts - pagePts,
      bottom: scrollPosYInPts + (pagePts * 2)
    }
    if (
      this._lastLoadArea &&
      this._lastLoadArea.top === loadArea.top &&
      this._lastLoadArea.bottom === loadArea.bottom) return
    this._lastLoadArea = loadArea
    return loadArea
  }

  private _updateScrollbar() {
    this._scrollBar.height = this._bg.height
    this._scrollBar.updateBar(this._bg.height)
    // pos
    let coef = this._getScrollBarYInCoef()
    this._scrollBar.setBarPosInCoef(coef)
  }

  private _getMaskHeight() {
    return this.height - (Column._padding * 2)
  }

  private _getScrollBarYInCoef() {
    let totalHeightInPx = this._getMaxColumnHeightInPx()
    return Math.abs(this._scrollYInPx) / totalHeightInPx
  }

  private _onDown(e: PIXI.InteractionEvent) {
    if (this._isMultitouch(e)) return
    this._isDragging = false
    this._isScrolling = false
    if (this._scrollingGsap) this._scrollingGsap.kill()
    this._isDragging = true
    this._isScrolling = true
    this._scrollingMouseMovementHistory = []
    this._scrollingMouseMovementHistory.push({ time: Date.now(), posY: e.data.global.y })
    this._scrollingStartMouseY = e.data.global.y
    this._scrollingStartY = this._scrollYInPx - this._scrollingStartMouseY
  }

  private _onMove(e: PIXI.InteractionEvent) {
    if (!this._isDragging) return
    if (this._isMultitouch(e)) {
      this._isDragging = false
      this._isScrolling = false
      return
    }
    this._scrollYInPx = this._scrollingStartY + e.data.global.y
    this._scrollingMouseMovementHistory.push({ time: Date.now(), posY: e.data.global.y })
  }

  private _onUp(e: PIXI.InteractionEvent) {
    if (!this._isDragging) return
    this._isDragging = false
    this._scrollingMouseMovementHistory.push({ time: Date.now(), posY: e.data.global.y })
    let scrollDirectionIsUp = Physics.getScrollMovememntDirection(this._scrollingMouseMovementHistory)
    if (scrollDirectionIsUp === undefined) {
      this._isScrolling = false
      return
    }
    let ignoreMoveTimeInMillisecondsOlderThan = 100
    let ignoreMoveDistanceLesserThan = 50
    let acceleration = Physics.getScrollAcceleration(this._scrollingMouseMovementHistory, scrollDirectionIsUp, ignoreMoveTimeInMillisecondsOlderThan, ignoreMoveDistanceLesserThan)
    if (acceleration.distance === 0) {
      this._isScrolling = false
      return
    }
    this._scrollingGsap = gsap.to(this, {
      _scrollYInPx: scrollDirectionIsUp ? this._scrollYInPx - acceleration.distance : this._scrollYInPx + acceleration.distance,
      duration: acceleration.duration,
      onInterrupt: () => {
        this._isDragging = false
        this._isScrolling = false
      },
      onComplete: () => {
        this._isDragging = false
        this._isScrolling = false
      }
    })
  }

  private _disableCameraController() {
    globalThis.viewport.plugins.pause("drag")
    globalThis.viewport.plugins.pause("wheel")
    globalThis.viewport.plugins.pause("pinch")
  }

  private _enableCameraController() {
    globalThis.viewport.plugins.resume("drag")
    globalThis.viewport.plugins.resume("wheel")
    globalThis.viewport.plugins.resume("pinch")
  }

  private _isMultitouch(e: PIXI.InteractionEvent) {
    return (
      e.data.originalEvent instanceof TouchEvent &&
      e.data.originalEvent.touches.length > 1
    )
  }

  private _getCardYInPx(beforeHeightInPts: number, beforeCardsCount: number) {
    return this._scrollYInPx + (beforeHeightInPts * Board.pixelsPerPoint) + (Column._marginBetweenCards * (beforeCardsCount > 0 ? beforeCardsCount - 1 : beforeCardsCount))
  }

  private _updateCardsYPoisition() {
    for (let i = 0; i < this._cards.length; i++) {
      this._cards[i].y = this._getCardYInPx(this._cards[i].cardsAboveInPts, this._cards[i].cardsAboveCount)
    }
  }

  private _updateCardsResolution() {
    for (let i = 0; i < this._cards.length; i++) {
      this._cards[i].updateResolution()
    }
  }

  private _addCard(loadedCard: any) {
    if (!loadedCard.name) loadedCard.name = ''
    let hexColor = parseInt(loadedCard.color.replace('#', ''), 16)
    let card = new Card(loadedCard.id, hexColor, loadedCard.height, loadedCard.before_height, loadedCard.before_cards_count, loadedCard.name)
    card.x = 0
    card.y = (loadedCard.before_height * Board.pixelsPerPoint) + (Column._marginBetweenCards * loadedCard.before_cards_count)
    this._cards.push(card)
    this._cardsContianer.addChild(card)
  }

  private _getColumnWidth() {
    return Column._padding + Card.width + Column._padding
  }

  private _getMaxColumnHeightInPx() {
    return Column._padding + (Board.pixelsPerPoint * this.heightInPt) + (Column._marginBetweenCards * (this.cardsCount > 0 ? this.cardsCount - 1 : 0)) + Column._padding
  }

  private _getLocalYPts() {
    return this._getMaxColumnHeightInPx() / this.heightInPt
  }

}