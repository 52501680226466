
// 1 колонки
// 2 прямоугольники текстов карт
// 3 тексты карт

export enum LevelOfDetailEnum {
  Columns = 1,
  ColumnCardRectangles = 2,
  ColumnCardTexts = 3
}

export default class LevelOfDetailManager {

  public static getLevelOfDetailEnum(cameraZoom: number): LevelOfDetailEnum {
    if (0.5 <= cameraZoom) return LevelOfDetailEnum.ColumnCardTexts
    if (0.32 <= cameraZoom) return LevelOfDetailEnum.ColumnCardRectangles
    return LevelOfDetailEnum.Columns
  }

}