import React, { useEffect } from 'react';
import './App.css';
import BigData from './routes/BigData';
import EasterEgg from './routes/EasterEgg';
import Config from './models/Config';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

function App() {
  const [isConfigLoaded, setIsConfigLoaded]  = React.useState<boolean>(false)
  const loadGameConfig = async () => {
    await Config.loadConfig()
    setIsConfigLoaded(true)
  }
  useEffect(() => {
    loadGameConfig()
  }, [])
  return (
    <div className='w-100 h-100'>
      <Router>
        <Routes>
          <Route path="/" element={isConfigLoaded && <BigData/>}/>
          <Route path="easter-egg" element={<EasterEgg/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
