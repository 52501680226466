import * as PIXI from 'pixi.js'

export default class Physics {

  public static hasCollision2d(rectA: PIXI.Rectangle, rectB: PIXI.Rectangle): boolean {
    return (
      Physics._hasCollision2dByX(rectA, rectB) &&
      Physics._hasCollision2dByY(rectA, rectB)
    )
  }

  public static getDistanceCoefBetweenBounds(rectA: PIXI.Rectangle, rectB: PIXI.Rectangle) {
    let distance = Physics._getDistanceBetweenBounds(rectA, rectB)
    if (distance === undefined) return
    distance.x = distance.x / rectA.width
    distance.y = distance.y / rectA.height
    let maxDistanceCoef = distance.x > distance.y ? distance.x : distance.y
    return maxDistanceCoef
  }

  public static getScrollAcceleration(movementHistory: { time: number, posY: number }[], directionIsUp: boolean, ignoreMillisecondsOlderThan: number, ignoreMoveDistanceLesserThan: number) {
    movementHistory = Physics._getScrollMovementSanitize(movementHistory)
    const tail = Physics._getScrollMovementCropTail(movementHistory, ignoreMillisecondsOlderThan, directionIsUp)
    const timeMaxTail = Physics._getScrollMovementHistoryTimeMax(tail)
    const timeMinTail = Physics._getScrollMovementHistoryTimeMin(tail)
    if (typeof timeMaxTail === 'number' || typeof timeMinTail === 'number') {
      return { distance: 0, duration: 0 }
    }
    const timeDiff = timeMaxTail.time - timeMinTail.time
    const posYDiff = directionIsUp ? timeMinTail.posY - timeMaxTail.posY : timeMaxTail.posY - timeMinTail.posY
    if (posYDiff < ignoreMoveDistanceLesserThan) {
      return { distance: 0, duration: 0 }
    }
    let duration = timeDiff / 10
    let distance = posYDiff * 10
    return { distance: distance, duration: duration }
  }

  public static getScrollMovememntDirection(movementHistory: { time: number, posY: number }[]) {
    movementHistory = Physics._getScrollMovementSanitize(movementHistory)
    if (movementHistory.length < 2) return
    const lastHistory = movementHistory[movementHistory.length - 1]
    const penultimateHistory = movementHistory[movementHistory.length - 2]
    const directionIsUp = penultimateHistory.posY >= lastHistory.posY
    return directionIsUp
  }

  private static _getScrollMovementSanitize(movementHistory: { time: number, posY: number }[]) {
    let ret = []
    for (let i = 0, prevMovement = null; i < movementHistory.length; i++) {
      if (prevMovement === null || (prevMovement && prevMovement.posY !== movementHistory[i].posY)) {
        ret.push(movementHistory[i])
      }
      prevMovement = movementHistory[i]
    }
    return ret
  }

  private static _getScrollMovementCropTail(movementHistory: { time: number, posY: number }[], ignoreMillisecondsOlderThan: number, directionIsUp: boolean): { time: number, posY: number }[] {
    const lastHistory = movementHistory[movementHistory.length - 1]
    const tailHistoryMinimalTime = lastHistory.time - ignoreMillisecondsOlderThan
    let ret = []
    for (let i = movementHistory.length - 1, lastPosY = movementHistory[i].posY; i >= 0; i--) {
      if (movementHistory[i].time < tailHistoryMinimalTime) break
      if (directionIsUp && movementHistory[i].posY < lastPosY) break
      if (!directionIsUp && movementHistory[i].posY > lastPosY) break
      ret.push(movementHistory[i])
      lastPosY = movementHistory[i].posY
    }
    return ret
  }

  private static _getScrollMovementHistoryTimeMax(movementHistory: { time: number, posY: number }[]): { time: number, posY: number } {
    let movementHistoryMax = movementHistory[0]
    for (let i = 0; i < movementHistory.length; i++) {
      if (movementHistoryMax.time < movementHistory[i].time) {
        movementHistoryMax = movementHistory[i]
      }
    }
    return movementHistoryMax
  }

  private static _getScrollMovementHistoryTimeMin(movementHistory: { time: number, posY: number }[]): { time: number, posY: number } {
    let movementHistoryMin = movementHistory[0]
    for (let i = 0; i < movementHistory.length; i++) {
      if (movementHistoryMin.time > movementHistory[i].time) {
        movementHistoryMin = movementHistory[i]
      }
    }
    return movementHistoryMin
  }

  private static _hasCollision2dByX(rectA: PIXI.Rectangle, rectB: PIXI.Rectangle): boolean {
    return (
      (
      rectA.x < (rectB.x + rectB.width) &&
      rectA.x > rectB.x
      ) || (
      rectB.x < (rectA.x + rectA.width) &&
      rectB.x > rectA.x
      )
    )
  }

  private static _hasCollision2dByY(rectA: PIXI.Rectangle, rectB: PIXI.Rectangle): boolean {
    return (
      (
      rectA.y < (rectB.y + rectB.height) &&
      rectA.y > rectB.y
      ) || (
      rectB.y < (rectA.y + rectA.height) &&
      rectB.y > rectA.y
      )
    )
  }
  
  private static _getDistanceBetweenBounds(rectA: PIXI.Rectangle, rectB: PIXI.Rectangle): { x: number, y: number } | undefined {
    let distance: { x: number, y: number } = {
      x: 0,
      y: 0
    }
    if (rectA.x <= rectB.x) distance.x = rectB.x - rectA.x
    if (rectA.x > rectB.x) distance.x = rectA.x - rectB.x
    if (rectA.y <= rectB.y) distance.y = rectB.y - rectA.y
    if (rectA.y > rectB.y) distance.y = rectA.y - rectB.y
    return distance
  }
}