import * as PIXI from 'pixi.js'
import Backend from './Backend'
import Column from './Column'
import { LevelOfDetailEnum } from './LevelOfDetailManager'
import Physics from './Physics'

export default class Board extends PIXI.Container {

  public columns: Column[] = []
  public static pixelsPerPoint = 20
  private static _marginBetweenColumns = 10
  private _isPending: boolean = false

  public async init() {
    await this._loadColumns()
  }

  public async setCameraPosition(lod: LevelOfDetailEnum, cameraVisibleBounds: PIXI.Rectangle, cameraLoadBounds: PIXI.Rectangle) {
    if (lod === LevelOfDetailEnum.Columns) {
      this.columns.forEach(column => column.deleteCards())
      // return
    }
    let cameraLoadColumns = this._convertBoundsToColumns(cameraLoadBounds)
    if (cameraLoadColumns === undefined) return
    //
    if (this._isPending) return
    this._isPending = true
    let cards = []
    if (lod !== LevelOfDetailEnum.Columns) {
      cards = await Backend.loadCardsByBounds(cameraLoadColumns, lod)
      if (cards === undefined) cards = []
    }
    await this._setChildCameraPosition(lod, cameraVisibleBounds, cameraLoadBounds, cards)
    this._isPending = false
  }

  private async _setChildCameraPosition(lod: LevelOfDetailEnum, cameraVisibleBounds: PIXI.Rectangle, cameraLoadBounds: PIXI.Rectangle, cards: any[]) {
    let promises: Promise<void>[] = []
    this.columns.forEach(column => {
      let columnBounds = new PIXI.Rectangle(
        this.x + column.x,
        this.y + column.y,
        column.width,
        column.height
      )
      if (!Physics.hasCollision2d(columnBounds, cameraLoadBounds)) {
        column.deleteCards()
        return
      }
      let columnCards = cards.filter(c => c.column_id === column.id)
      let promise = column.setCameraPosition(lod, cameraVisibleBounds, cameraLoadBounds, columnCards)
      promises.push(promise)
    })
    await Promise.all(promises)
  }

  private _convertBoundsToColumns(cameraBounds: PIXI.Rectangle): undefined | any {
    let columns: any[] = []
    this.columns.forEach(column => {
      let columnBounds = new PIXI.Rectangle(
        this.x + column.x,
        this.y + column.y,
        column.width,
        column.height
      )
      if (!Physics.hasCollision2d(columnBounds, cameraBounds)) return
      let loadArea = column.getLoadArea()
      if (loadArea === undefined) return
      columns.push({ id: column.id, top: loadArea.top, bottom: loadArea.bottom })
    })
    return columns
  }

  private async _loadColumns() {
    let loadedColumns: any[] = await Backend.getColumns()
    loadedColumns.forEach((loadedColumn, i) => {
      let column = new Column(
        loadedColumn.id,
        loadedColumn.cards_count,
        loadedColumn.height,
        loadedColumn.name,
        loadedColumn.first_card_id,
        loadedColumn.last_card_id
      )
      column.x += (column.width + Board._marginBetweenColumns) * i
      this.columns.push(column)
      this.addChild(column)
    })
  }

}