import { LevelOfDetailEnum } from "./LevelOfDetailManager"

export default class Backend {

  private static _pendingCards: { column_id: number, h: number }[] = []
  private static _cachedCards: any[] = []

  public static async getColumns(fields: string[] = ["id","cards_count","height","name","first_card_id","last_card_id"], ids: number[] = []) {
    return await Backend._request('get_columns', {
      "fields": fields,
      "ids": ids
    })
  }

  private static async _getColumnCards(columns: { id: number, top: number, bottom: number }[], fields: string[] = ["id", "column_id","name","name_length","height","before_cards_count","before_height","color","description","description_length"]) {
    return await Backend._request('get_cards_in_columns', {
      "columns": columns,
      "fields": fields
    })
  }

  public static async getCards(ids: number[], fields: string[] = ["id","name","name_length","height","before_cards_count","before_height","color","description","description_length"]) {
    let cards = await Backend._request('get_cards', {
      "ids": ids,
      "fields": fields
    })
    return cards
  }

  public static async loadCardsByBounds(loadColumns: { id: number, top: number, bottom: number }[], lod: LevelOfDetailEnum) {
    loadColumns.forEach(loadColumn => {
      loadColumn.top = Math.floor(loadColumn.top)
      loadColumn.bottom = Math.ceil(loadColumn.bottom)
    })
    let pendingCards = Backend._loadColumnsToPendingCards(loadColumns)
    if (pendingCards.length === 0) return
    let pendingColumns = Backend._pendingCardsToColumns(pendingCards)
    let loadColumns4Request = Backend._prepareLoadColumnsToRequest(pendingColumns)
    let fields = Backend._getLODFields(lod)
    let cards = await Backend._getColumnCards(loadColumns4Request, fields)
    return cards
  }

  private static _getLODFields(lod: LevelOfDetailEnum): string[] {
    let fields = ["id", "column_id","name","name_length","height","before_cards_count","before_height","color","description","description_length"]
    fields = fields.filter(f => !["name_length","description","description_length"].includes(f))
    if ([LevelOfDetailEnum.ColumnCardRectangles].includes(lod)) {
      fields = fields.filter(f => !["name"].includes(f))
    }
    return fields
  }

  private static _loadColumnsToPendingCards(loadColumns: { id: number, top: number, bottom: number }[]): { column_id: number, h: number }[] {
    let pendingCards: { column_id: number, h: number }[] = []
    loadColumns.forEach(loadColumn => {
      for (let h = loadColumn.top; h <= loadColumn.bottom; h++) {
        if (h < 0) continue
        pendingCards.push({ column_id: loadColumn.id, h: h })
      }
    })
    return pendingCards
  }

  private static _prepareLoadColumnsToRequest(loadColumns: any[]) {
    let loadColumns4Request: any[] = []
    loadColumns.forEach(loadColumn => {
      loadColumn.top = Math.floor(loadColumn.top)
      loadColumn.bottom = Math.floor(loadColumn.bottom)
      loadColumns4Request.push({
        id: loadColumn.id,
        from_height: loadColumn.top,
        to_height: loadColumn.bottom
      })
    })
    return loadColumns4Request
  }

  private static _pendingCardsToColumns(pendingCards: any[]) {
    let columns: any[] = []
    let columnIds: number[] = []
    pendingCards.forEach(pendingCard => {
      if (columnIds.findIndex(columnId => columnId === pendingCard.column_id) !== -1) return
      columnIds.push(pendingCard.column_id)
    })
    columnIds.forEach(columnId => {
      let cards = pendingCards.filter(c => c.column_id === columnId)
      let cardsHeights: number[] = []
      cards.forEach(c => {
        cardsHeights.push(c.h)
      })
      cardsHeights.sort((a, b) => a - b)
      let intervals = Backend._arrayToHeightIntervals(cardsHeights)
      intervals.forEach(interval => {
        columns.push({ id: columnId, top: interval.top, bottom: interval.bottom })
      })
    })
    return columns
  }

  private static _arrayToHeightIntervals(cardsHeights: number[]) {
    let intervals = []
    for (let i = 0, start = 0; i <= cardsHeights.length; i++) {
      if (i === 0) continue
      if (cardsHeights[i] === cardsHeights[i - 1] + 1) continue
      intervals.push({top: cardsHeights[start], bottom: cardsHeights[i - 1]})
      start = i
    }
    return intervals
  }

  private static async _request(name: string, data: any) {
    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    let raw = JSON.stringify(data)
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }
    let res = await fetch("https://api.otiva.ru/api/v3/" + name, requestOptions)
    return res.json()
  }
}