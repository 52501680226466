import * as PIXI from 'pixi.js'
import Board from "./Board"
import LevelOfDetailManager from "./LevelOfDetailManager"

export default class CommonBoard extends PIXI.Container {
  private static _board: Board
  public static renderCustomly: () => void

  private static _renderEnablerOpinionIds: string[] = []
  private static _isAutoRenderEnabled: boolean = false

  constructor(render: () => void) {
    super()
    CommonBoard.renderCustomly = () => {
      if (CommonBoard._isAutoRenderEnabled) return
      render()
    }
    CommonBoard._board = new Board()
    this.addChild(CommonBoard._board)

    PIXI.Ticker.system.add(() => {
      if (!CommonBoard._isAutoRenderEnabled) return
      render()
    }, PIXI.UPDATE_PRIORITY.LOW)
  }
  public static async init() {
    await CommonBoard._board.init()
  }
  public static loadData() {
    let levelOfDetail = LevelOfDetailManager.getLevelOfDetailEnum(globalThis.viewport.scaled)
    let cameraVisibleBounds = globalThis.viewport.getVisibleBounds()
    let cameraLoadBounds = new PIXI.Rectangle(
      cameraVisibleBounds.x - cameraVisibleBounds.width,
      cameraVisibleBounds.y - cameraVisibleBounds.height,
      cameraVisibleBounds.width * 3,
      cameraVisibleBounds.height * 3
    )
    CommonBoard._board.setCameraPosition(levelOfDetail, cameraVisibleBounds, cameraLoadBounds).then(() => {
      CommonBoard.renderCustomly()
    })
  }
  public static enableAutoRender(opinionId: string) {
    if (CommonBoard._renderEnablerOpinionIds.includes(opinionId)) return
    CommonBoard._isAutoRenderEnabled = true
    CommonBoard._renderEnablerOpinionIds.push(opinionId)
  }
  public static disableAutoRender(opinionId: string) {
    let idx = CommonBoard._renderEnablerOpinionIds.indexOf(opinionId)
    if (idx > -1) {
      CommonBoard._renderEnablerOpinionIds.splice(idx, 1)
      if (CommonBoard._renderEnablerOpinionIds.length === 0) {
        CommonBoard._isAutoRenderEnabled = false
      }
    }
  }
}