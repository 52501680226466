import * as PIXI from 'pixi.js'
import Board from './Board'

export default class Card extends PIXI.Container {

  public id: number
  public heightInPts: number
  public cardsAboveInPts: number
  public cardsAboveCount: number
  public static width: number = 280

  // public text: PIXI.BitmapText
  public text: PIXI.Text
  public rectangles: PIXI.Container

  constructor(id: number, color: number, heightInPts: number, cardsAboveInPts: number, cardsAboveCount: number, name: string) {

    super()

    this.id = id

    this.heightInPts = heightInPts
    this.cardsAboveInPts = cardsAboveInPts
    this.cardsAboveCount = cardsAboveCount

    name = Card._wrapText(name)

    // this.text = new PIXI.BitmapText(name, { fontSize: 16, fontName: PIXI.BitmapFont.available['OpenSans-Regular'].font, tint: 0x000000 })
    this.text = new PIXI.Text(name, { fontSize: 16, fontFamily: 'ttf', fill: 0x1a1a1a })
    this.text.resolution = globalThis.viewport.scaled * window.devicePixelRatio
    this.text.x = 10
    this.text.visible = false

    let bg = new PIXI.Sprite(PIXI.Texture.WHITE)
    bg.tint = 0xffffff
    bg.x = this.x
    bg.y = this.y
    bg.width = Card.width
    bg.height = Board.pixelsPerPoint * heightInPts
    let coloredLine = new PIXI.Sprite(PIXI.Texture.WHITE)
    coloredLine.tint = color
    coloredLine.x = this.x
    coloredLine.y = this.y
    coloredLine.width = 5
    coloredLine.height = Board.pixelsPerPoint * heightInPts
    
    this.rectangles = new PIXI.Container()
    this.rectangles.visible = false
    for (let y = 0; y < this.heightInPts; y++) {
      let rect = new PIXI.Sprite(PIXI.Texture.WHITE)
      rect.tint = 0xcccccc
      let rectWidth = Card.width - Board.pixelsPerPoint * 2
      rect.x = this.x + Board.pixelsPerPoint + 2
      rect.y = this.y + (Board.pixelsPerPoint * y) + 2
      rect.width = rectWidth * (0.5 + 0.5 * Math.random())
      rect.height = Board.pixelsPerPoint - 4
      this.rectangles.addChild(rect)
    }

    this.addChild(bg)
    this.addChild(coloredLine)
    this.addChild(this.rectangles)
    this.addChild(this.text)
  }

  public setTitle(title: string) {
    let newText = Card._wrapText(title)
    if (this.text.text === newText) return
    this.text.text = newText
  }

  public updateResolution() {
    if (this.text.resolution === globalThis.viewport.scaled * window.devicePixelRatio) return
    this.text.resolution = globalThis.viewport.scaled * window.devicePixelRatio
  }

  private static _wrapText(text: string, wordWrapWidthInSymbols: number = 25) {
    let textResult = ''
    if (text.length < wordWrapWidthInSymbols) return text
    let txt = ''
    text.split(' ').forEach((word, i, words) => {
      txt += word
      if (txt.length > wordWrapWidthInSymbols) {
        textResult += txt + "\n"
        txt = ''
        return
      }
      if (i > 0 && i < words.length) txt += ' '
    })
    return textResult
  }

}