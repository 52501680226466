import React, { useEffect, useRef } from 'react'
import * as PIXI from 'pixi.js'
import { WebfontLoaderPlugin } from 'pixi-webfont-loader'

// PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
const stage = new PIXI.Container()
const renderer = new PIXI.Renderer({ width: window.innerWidth, height: window.innerHeight, backgroundColor: 0xFFFFFF })

PIXI.Loader.registerPlugin(WebfontLoaderPlugin);


export default function EasterEgg() {
  const elementRef = useRef<HTMLDivElement | null>(null)
  const loadText = () => {
    let sizes = [8, 10, 12, 14, 16, 20, 24, 28, 32, 64, 128]
    let y = 0
    sizes.forEach(fontSize => {
      let text = new PIXI.Text("Arial. ttf. Size " + fontSize, { fontSize: fontSize, fontFamily: 'Arial' })
      text.y = y
      y += fontSize
      stage.addChild(text)
    })
    sizes.forEach(fontSize => {
      let text = new PIXI.BitmapText("Open Sans. msdf. Size " + fontSize, { fontSize: fontSize, fontName: PIXI.Loader.shared.resources['msdf'].bitmapFont!.font, tint: 0x000000 })
      text.y = y
      y += fontSize
      stage.addChild(text)
    })
    renderer.render(stage)
  }
  useEffect(() => {
    if (!elementRef.current) return
    renderer.resize(elementRef.current.clientWidth, elementRef.current.clientHeight)
    window.onresize = () => {
      if (!elementRef.current) return
      renderer.resize(elementRef.current.clientWidth, elementRef.current.clientHeight)
      renderer.render(stage)
    }
    elementRef.current.appendChild(renderer.view)
    if (!PIXI.Loader.shared.resources['msdf']) {
    PIXI.Loader.shared
      .add('msdf', '/fonts/OpenSans/OpenSans-Regular.fnt')
      .load(() => {
        loadText()
      })
    } else {
      loadText()
    }
  }, [])
  return (
    <div className='w-100 h-100 p-0 m-0 overflow-hidden' ref={elementRef}></div>
  )
}