import * as PIXI from 'pixi.js'
import Board from './Board'

export default class ScrollBar extends PIXI.Container {

  public static width = 10
  public onScroll: Function | null = null
  private _bg: PIXI.Sprite
  private _bar: PIXI.Sprite
  private _visiblePtsPerPage: number
  private _maxPts: number
  private _barPosInCoef: number = 0
  private _visibleHeightInPx: number
  private static _minimalBarHeightInPx = 20

  constructor(visibleHeightInPx: number, maxPts: number) {
    super()
    this._visibleHeightInPx = visibleHeightInPx
    this._visiblePtsPerPage = this._visibleHeightInPx / Board.pixelsPerPoint
    this._maxPts = maxPts

    this._bg = new PIXI.Sprite(PIXI.Texture.WHITE)
    this._bg.tint = 0xffffff
    this._bg.width = ScrollBar.width
    this._bg.height = visibleHeightInPx
    this.addChild(this._bg)

    this._bar = new PIXI.Sprite(PIXI.Texture.WHITE)
    this._bar.tint = 0xff0000
    this._bar.width = ScrollBar.width
    this._bar.height = this._getBarHeightInPx()
    this.updateBar(this._visibleHeightInPx)
    this.addChild(this._bar)
  }

  public setBarPosInCoef(coef: number) {
    this._barPosInCoef = coef
    this.updateBar(this._visibleHeightInPx)
  }

  public updateBar(visibleHeightInPx: number) {
    this._visibleHeightInPx = visibleHeightInPx
    this._visiblePtsPerPage = this._visibleHeightInPx / Board.pixelsPerPoint
    this._bar.height = this._getBarHeightInPx()
    this._bar.y = this._bg.height * this._barPosInCoef
  }

  private _getBarHeightInPx() {
    this.visible = this._visiblePtsPerPage < this._maxPts
    let barHeightSizeInCoef = this._visiblePtsPerPage / this._maxPts
    let barHeightInPx = this._bg.height * barHeightSizeInCoef
    return barHeightInPx > ScrollBar._minimalBarHeightInPx ? barHeightInPx : ScrollBar._minimalBarHeightInPx
  }

}