import { useEffect, useRef } from 'react'
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'
import { WebfontLoaderPlugin } from 'pixi-webfont-loader'
import CommonBoard from '../models/CommonBoard'
declare global {
  export var viewport: Viewport
}
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
PIXI.settings.RESOLUTION = window.devicePixelRatio;
PIXI.settings.ROUND_PIXELS = true
// PIXI.settings.ANISOTROPIC_LEVEL = 4
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH
const stage = new PIXI.Container()
const renderer = new PIXI.Renderer({ backgroundColor: 0x5a5be9, antialias: false, autoDensity: true })
globalThis.viewport = new Viewport({
  interaction: renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
})
const commonBoard = new CommonBoard(() => { renderer.render(stage) })
globalThis.viewport.addChild(commonBoard)
PIXI.Loader.registerPlugin(WebfontLoaderPlugin)

document.addEventListener('wheel', (e) => {
  if (e.ctrlKey) e.preventDefault()
}, {
  passive: false,
})

function BigData() {
  const elementRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!elementRef.current) return
    renderer.resize(elementRef.current.clientWidth, elementRef.current.clientHeight)
    globalThis.viewport.resize(renderer.width, renderer.height, commonBoard.width, commonBoard.height)
    window.onresize = () => {
      if (!elementRef.current) return
      renderer.resize(elementRef.current.clientWidth, elementRef.current.clientHeight)
      globalThis.viewport.resize(renderer.width, renderer.height, commonBoard.width, commonBoard.height)
      CommonBoard.renderCustomly()
    }
    elementRef.current.appendChild(renderer.view)
    
    stage.addChild(globalThis.viewport)
    // globalThis.viewport.plugins.add("my-control-plugin", new ControlPlugin(globalThis.viewport))
    globalThis.viewport
      .drag({
        pressDrag: false,
        wheel: true,
        ignoreKeyToPressOnTouch: true
      })
      .wheel({
        keyToPress: ['ControlLeft'],
        trackpadPinch: true,
        wheelZoom: false
      })
      .pinch()

    if (!PIXI.Loader.shared.resources['msdf']) {
      PIXI.Loader.shared
        // .add('msdf', '/fonts/OpenSans/OpenSans-Regular.fnt')
        .add('ttf', '/fonts/OpenSans/OpenSans-Regular.ttf')
        .load(() => {
          CommonBoard.init().then(() => {
            CommonBoard.loadData()
          })
        })
    } else {
      CommonBoard.init().then(() => {
        CommonBoard.loadData()
      })
    }
    globalThis.viewport.addListener('moved', () => {
      CommonBoard.renderCustomly()
    })
    globalThis.viewport.addListener('moved-end', () => {
      CommonBoard.loadData()
    })
    return () => {
      globalThis.viewport.removeChildren()
    }
  }, [])
  
  return (
    <div className='w-100 h-100 p-0 m-0 overflow-hidden'>
    <div className='position-absolute w-100 d-flex justify-content-between p-3'>
      <img src="/panel-test/logo-btn.png" alt="" className='' />
      <img src="/panel-test/panel-middle.png" alt="" className='' />
      <div>
        <img src="/panel-test/panel-filter.png" alt="" className='' />
        <img src="/panel-test/panel-user-logo.png" alt="" className='ps-3' />
      </div>
    </div>
    <div className='w-100 h-100 p-0 m-0 overflow-hidden' ref={elementRef}></div>
    </div>
  )
}
export default BigData